<template>
  <v-navigation-drawer
    fixed
    app
    floating
    :value="drawer"
    class="my-4 ms-4 border-radius-lg"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
    style="z-index: 200"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <v-img
              src="@/../../shared/app/assets/logo_boxed.png"
              class="navbar-brand-img ms-3"
              width="36"
              heigth="36"
            >
            </v-img>
            <span class="ms-2 font-weight-bold text-sm"
              >LABSTREAM {{ packageInfo.version }}</span
            >
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr class="horizontal dark mb-4" />

    <v-list expand nav dense>
      <div :key="index" v-for="(item, index) in drawerItems">
        <v-list-group
          expand
          :ripple="false"
          v-model="item.active"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-2"
          active-class="item-active"
        >
          <template v-slot:activator>
            <div
              :style="'background-color:' + item.backgroundcolor"
              class="list-item-icon shadow border-radius-md mx-2 align-center mt-1 mb-1"
            >
              <v-icon
                v-if="item.icon"
                v-html="item.icon"
                size="18"
                :color="item.iconcolor"
              />
            </div>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(item.title)"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :ripple="false"
            link
            class="mb-0 no-default-hover"
            v-for="child in getChilds(item.items)"
            :key="child.title"
            :to="child.link"
          >
            <div v-if="itemHasModule(child) && itemHasRights(child)">
              <span class="v-list-item-mini">{{ $t(child.title)[0] }}</span>

              <v-list-item-content class="ms-6 ps-7" v-if="!child.items">
                <v-list-item-title
                  v-text="$t(child.title)"
                  @click="listClose($event)"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-7 py-0" v-if="child.items">
                <v-list-group
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <span class="v-list-item-mini">{{
                      $t(child.title)[0]
                    }}</span>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        append-icon="fas fa-angle-down me-auto ms-1"
                        active-class="item-active"
                        class="mb-0"
                      >
                        <template v-slot:activator class="mb-0">
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              v-text="child.title"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <span class="v-list-item-mini">{{
                      $t(child2.title)[0]
                    }}</span>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="child2.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
      </div>

      <hr class="horizontal dark my-4" />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
const packageInfo = require("../../../package.json");

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },

  computed: {
    drawerItems() {
      let items = [];
      let modules = [
        this.dashboardItems,
        this.crmItems,
        this.checkinItems,
        this.logisticItems,
        this.clinicItems,
        this.labBiochemicalItems,
        this.labMolecularItems,
        this.labGeneticItems,
        this.labSnpItems,
        this.opsItems,
        this.adminItems,
        this.superAdminItems,
      ];

      for (let x in modules) {
        let mod = modules[x];
        if (this.hasValidItems(mod)) items.push(mod);
      }

      return items;
    },
  },

  mounted() {
    this.opsItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#23aae1",
      active: true,
      title: "sidebar-ops-title",

      items: [],
    };

    if (!this.$store.state.hasExactRight("315")) {
      this.opsItems.items = [
        {
          title: "sidebar-ops-invoices",
          link: "/invoices",
          right: "172",
          appModule: "accounting",
        },
        {
          title: "sidebar-ops-invoices-reports",
          link: "/invoices-reports",
          right: "173",
          appModule: "accounting",
        },
        {
          title: "sidebar-ops-invoices-expired",
          link: "/invoices-expired",
          right: "302",
          appModule: "accounting",
        },

        {
          title: "sidebar-ops-delta",
          link: "/deltas",
          right: "310",
          appModule: "accounting",
        },
        {
          title: "sidebar-ops-delta-reports",
          link: "/deltas-reports",
          right: "310",
          appModule: "accounting",
        },
        {
          title: "sidebar-ops-proforma-builder",
          link: "/proforma-builder",
          right: "279",
          appModule: "crm",
        },
        {
          title: "sidebar-ops-proforma-exceptions",
          link: "/proforma-exceptions",
          right: "280",
          appModule: "crm",
        },
        {
          title: "sidebar-ops-proforma-reports",
          link: "/proforma-reports",
          right: "279",
          appModule: "crm",
        },
      ];
    }

    this.opsItems.items.push({
      title: "sidebar-ops-filemanager",
      link: "/filemanager",
      right: "36",
    });

    this.dashboardItems = {
      icon: "mdi-monitor-dashboard",
      iconcolor: "white",
      backgroundcolor: "#fdb813",
      active: true,
      title: "sidebar-dashboards-title",
      items: [
        {
          title: "sidebar-dashboards-calendar",
          link: "/dashboard-calendar",
          right: "124",
          appModule: "clinic",
        },
        {
          title: "sidebar-dashboards-clinic",
          link: "/dashboard-clinic",
          right: "234",
          appModule: "clinic",
        },
        {
          title: "sidebar-dashboards-laboratory",
          link: "/dashboard-lab",
          right: "168",
          appModule: "lab",
        },
        {
          title: "sidebar-dashboards-accountant",
          link: "/dashboard-accountant",
          right: "170",
          appModule: "accounting",
        },
      ],
    };

    this.crmItems = {
      icon: "mdi-monitor-dashboard",
      iconcolor: "white",
      backgroundcolor: "#92278f",
      active: true,
      title: "sidebar-crm-title",
      appModule: "crm",
      items: [
        {
          title: "sidebar-dashboards-sales",
          link: "/dashboard-sales",
          right: "278",
        },
        {
          title: "sidebar-sales-calendar",
          link: "/sales-calendar",
          right: "259",
        },
        {
          title: "sidebar-sales-customers",
          link: "/sales-customers",
          right: "264",
        },
        {
          title: "sidebar-sales-surveys",
          link: "/sales-surveys",
          right: "268",
        },
        {
          title: "sidebar-sales-proforma",
          link: "/sales-proforma",
          right: "273",
        },
        {
          title: "sidebar-conf-agents",
          link: "/agents",
          right: "118",
        },
        {
          title: "sidebar-conf-crm-categories",
          link: "/crm-categories",
          right: "211",
        },
        {
          title: "sidebar-conf-crm-documents",
          link: "/crm-documents",
          right: "317",
        },
        {
          title: "sidebar-conf-crm-video",
          link: "/crm-video",
          right: "322",
        },
        {
          title: "sidebar-conf-crm-news",
          link: "/crm-news",
          right: "339",
        },
      ],
    };

    this.logisticItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#009999",
      active: true,
      title: "sidebar-logistic",
      appModule: "crm",
      items: [
        {
          title: "sidebar-logistic-shipments",
          link: "/logistic-shipments",
          right: "329",
        },
        {
          title: "sidebar-logistic-kits",
          link: "/logistic-kits",
          right: "333",
        },
      ],
    };

    this.checkinItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#6f8b3a",
      active: true,
      title: "sidebar-checkin",

      items: [
        {
          title: "sidebar-lab-biochemical-checkin",
          link: "/lab-biochemical-checkin",
          right: "180",
          appModule: "lab-biochemical",
        },
        {
          title: "sidebar-lab-molecular-checkin",
          link: "/lab-molecular-checkin",
          right: "180",
          appModule: "lab",
        },

        {
          title: "sidebar-clinic-checkin",
          link: "/clinic-checkin",
          right: "233",
          appModule: "clinic",
        },

        {
          title: "sidebar-checkin-manager",
          link: "/checkin-manager",
          right: "281",
        },
        {
          title: "sidebar-precheckin-manager",
          link: "/precheckin-manager",
          right: "281",
        },
        {
          title: "sidebar-family-groups",
          link: "/lab-families",
          right: "180",
          appModule: "lab",
        },
      ],
    };

    this.labGeneticItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#d11e5d",
      active: true,
      title: "sidebar-lab-genetic-profiles",
      appModule: "lab",
      items: [
        {
          title: "sidebar-lab-genetic-profiles",
          link: "/lab-genetic-profiles",
          right: "306",
        },
        {
          title: "sidebar-lab-genetic-profiles-compare",
          link: "/lab-genetic-profiles-compare",
          right: "306",
        },
        {
          title: "sidebar-lab-genetic-profiles-manage",
          link: "/lab-genetic-profiles-manage",
          right: "306",
        },
      ],
    };

    this.labSnpItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#d11e5d",
      active: true,
      title: "sidebar-lab-snp-title",
      appModule: "lab",
      items: [
        {
          title: "sidebar-lab-snp-profiles",
          link: "/lab-snp-profiles",
          right: "306",
        },
        {
          title: "sidebar-lab-snp-profiles-manage",
          link: "/lab-snp-profiles-manage",
          right: "306",
        },
        {
          title: "sidebar-lab-snp-tables",
          link: "/lab-snp-tables",
          right: "306",
        },
        {
          title: "sidebar-lab-snp-manage-tables",
          link: "/lab-snp-manage-tables",
          right: "306",
        },
      ],
    };

    this.labBiochemicalItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#d11e5d",
      active: true,
      title: "sidebar-lab-biochemical-title",
      appModule: "lab-biochemical",
      items: [
        {
          title: "sidebar-lab-samples",
          link: "/lab-biochemical-samples",
          right: "290",
        },
        {
          title: "sidebar-lab-clinic",
          link: "/lab-biochemical",
          right: "160",
        },
      ],
    };

    this.labMolecularItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#d11e5d",
      active: true,
      title: "sidebar-lab-genetic-title",
      appModule: "lab",
      items: [
        {
          title: "sidebar-lab-samples",
          link: "/lab-samples",
          right: "290",
        },
        {
          title: "sidebar-lab-elegibility",
          link: "/lab-elegibility",
          right: "193",
        },
        {
          title: "sidebar-lab-elegibility-notifications",
          link: "/lab-elegibility-notifications",
          right: "195",
        },

        {
          title: "sidebar-lab-molecular",
          link: "/lab-molecular",
          right: "160",
        },

        {
          title: "sidebar-lab-external",
          link: "/lab-external",
          right: "160",
        },

        {
          title: "sidebar-lab-reports",
          link: "/lab-reports",
          right: "197",
        },
        {
          title: "sidebar-lab-samples-collections",
          link: "/lab-collections",
          right: "200",
        },
        {
          title: "sidebar-lab-archive-samples",
          link: "/lab-archive-samples",
          right: "203",
        },
        {
          title: "sidebar-lab-download-samples",
          link: "/lab-download-samples",
          right: "294",
        },

        {
          title: "sidebar-lab-templates",
          link: "/lab-templates",
          right: "205",
        },
        {
          title: "sidebar-lab-templates-files",
          link: "/lab-templates-files",
          right: "206",
        },
        {
          title: "sidebar-lab-analisys-categories",
          link: "/lab-analisys-categories",
          right: "205",
        },
        {
          title: "sidebar-lab-samples-categories",
          link: "/lab-samples-categories",
          right: "205",
        },
      ],
    };

    this.clinicItems = {
      icon: "mdi-orbit",
      iconcolor: "white",
      backgroundcolor: "#00529b",
      active: true,
      title: "sidebar-clinic",
      appModule: "clinic",
      items: [
        {
          title: "sidebar-clinic-availability-services",
          link: "/clinic-availability-services",
          right: "242",
        },
        {
          title: "sidebar-clinic-availability-organizer",
          link: "/clinic-availability-organizer",
          right: "137",
        },
        {
          title: "sidebar-clinic-rooms",
          link: "/clinic-rooms",
          right: "129",
        },
        {
          title: "sidebar-clinic-medical-equipment",
          link: "/clinic-medical-equipment",
          right: "133",
        },
        {
          title: "sidebar-clinic-rooms-services",
          link: "/clinic-room-services",
          right: "129",
        },
        {
          title: "sidebar-clinic-service-equipment",
          link: "/clinic-service-equipment",
          right: "133",
        },
      ],
    };

    this.adminItems = {
      icon: "mdi-cog-outline",
      iconcolor: "white",
      backgroundcolor: "#6d6e70",
      active: true,
      title: "sidebar-conf-title",
      items: [
        {
          title: "sidebar-conf-accounting",
          link: "/accounting",
          right: "224",
          appModule: "accounting",
        },
        {
          title: "sidebar-conf-registry",
          link: "/registry",
          right: "147",
        },
        {
          title: "sidebar-conf-pricelists",
          link: "/pricelists",
          right: "210",
        },
        {
          title: "sidebar-conf-pricelists-files",
          link: "/pricelists-files",
          right: "338",
        },

        {
          title: "sidebar-conf-services",
          link: "/services",
          right: "138",
        },
        {
          title: "sidebar-conf-bundles",
          link: "/bundles",
          right: "143",
        },
        {
          title: "sidebar-conf-notifications",
          link: "/notifications",
          right: "49",
        },
        {
          title: "sidebar-auth-users",
          link: "/permissions-users",
          right: "17",
        },
        {
          title: "sidebar-auth-groups",
          link: "/permissions-groups",
          right: "17",
        },
        {
          title: "sidebar-conf-setup",
          link: "/identity",
          right: "217",
        },
      ],
    };

    if (!this.$store.state.hasExactRight("315")) {
      this.adminItems.items = [
        {
          title: "sidebar-conf-structures",
          link: "/structures",
          right: "250",
          appModule: "crm",
        },
        ...this.adminItems.items,
      ];
    }

    this.superAdminItems = {
      icon: "mdi-account-cog-outline",
      iconcolor: "white",
      backgroundcolor: "#8598ca",
      active: true,
      title: "sidebar-auth-title",
      right: "a",
      items: [
        {
          title: "sidebar-auth-apps",
          link: "/permissions-applications",
          right: "sa",
        },
        {
          title: "sidebar-auth-logs",
          link: "/logs",
          right: "sa",
        },
      ],
    };
  },

  data: () => ({
    packageInfo: packageInfo,
    opsItems: {},
    dashboardItems: {},
    crmItems: {},
    checkinItems: {},
    logisticItems: {},
    labMolecularItems: {},

    labGeneticItems: {},
    labSnpItems: {},
    clinicItems: {},
    adminItems: {},
    superAdminItems: {},
  }),

  methods: {
    itemHasModule(item) {
      if (
        this.$store.state.frontend &&
        this.$store.state.frontend.enabled_modules
      ) {
        let modules = this.$store.state.frontend.enabled_modules.split(",");
        if (!item.appModule || modules.includes(item.appModule)) return true;
      }

      return false;
    },

    itemHasRights(item) {
      if (!item.right) return true;
      if (item.right == "sa") {
        if (this.$store.state.isSuperAdmin()) return true;
      } else if (item.right == "a") {
        if (this.$store.state.isAdmin()) return true;
      } else if (this.$store.state.hasRight(item.right)) return true;
      return false;
    },

    hasValidItems(list) {
      if (!list || !list.items) return false;
      if (
        this.$store.state.frontend &&
        this.$store.state.frontend.enabled_modules
      ) {
        let modules = this.$store.state.frontend.enabled_modules.split(",");
        if (list.appModule && !modules.includes(list.appModule)) return false;
        if (!this.itemHasRights(list)) return false;
        for (let x = 0; x < list.items.length; x++) {
          if (this.itemHasRights(list.items[x])) return true;
        }
      }
      return false;
    },

    getChilds(list) {
      let childs = [];
      for (let x in list) {
        const item = list[x];
        if (this.$store.state.hasRight(item.right)) childs.push(item);
      }
      return childs;
    },

    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>

<style scoped>
.v-list-item__title {
  line-height: 1.1rem !important;
}

.list-item-icon {
  width: 32px;
  height: 32px;
  padding-left: 7px;
  padding-top: 3px;
  background-color: #344767;
}

.v-list-item__content {
  padding: 8px;
}
</style>
